import { Text } from "@mantine/core";
import React from "react";

export default function S3Organization() {
  return (
    <>
      <Text className="ec-font-bold ec-text-xl ec-tracking-wide ">
        Struggling with your visibility & growth?{" "}
        <span className="ec-text-[#eab308]">NO WORRIES!</span>
      </Text>
      <Text className=" ec-text-lg">
        Be part of technical ecosystem and reach out your right individual and
        organizations. Get access to tools and technologies as per your
        organizational requirements and stay ahead in world of technology.
      </Text>
      <Text>For College, Gov Org, Research Institutes etc.</Text>
    </>
  );
}
