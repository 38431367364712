import MainHeader from "@components/common/header/mainHeader";
import { Button, Text, Title, useMantineColorScheme } from "@mantine/core";
import { useWindowScroll } from "@mantine/hooks";
import { useRouter } from "next/router";
import { RocketLaunch } from "phosphor-react";
import { useState } from "react";
import ReactGA from "react-ga";

export default function Section1() {
  const { colorScheme } = useMantineColorScheme();
  const [scroll, scrollTo] = useWindowScroll();
  const [opened, setOpened] = useState(false);
  const handleBurgerClick = () => {
    setOpened(!opened);
  };
  const router = useRouter();
  return (
    <div
      style={
        {
          // backgroundImage: `url('/assets/LandingPage/Hero1_2/Section1_BG.svg')`,
          // backgroundImage: `url('https://i.ibb.co/0rF9NfT/Frame-3.png')`,
          // backgroundImage: `url('https://i.ibb.co/3WJRmYh/Frame-4-1.png')`,
          // backgroundPosition: "bottom",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
        }
      }
      className=" ec-w-screen ec-h-screen ec-bg-no-repeat lg:ec-bg-right-bottom ec-bg-top  ec-bg-cover ec-bg-[url('https://i.ibb.co/KmRytRr/Phone.png')] lg:ec-bg-[url('https://i.ibb.co/3WJRmYh/Frame-4-1.png')] ec-flex ec-flex-col ec-items-center ec-justify-start"
    >
      <MainHeader
        menu={false}
        opened={opened}
        handleBurgerClick={handleBurgerClick}
      />
      <div className=" ec-w-full  ec-h-full ec-p-5 lg:!ec-p-10 lg:!ec-flex-row ec-flex ec-flex-col  ec-gap-2">
        <div className="ec-w-full sm:ec-pt-10 lg:ec-pt-0 ec-flex ec-flex-col ec-items-center lg:!ec-items-start ec-gap-3 lg:ec-gap-8 ec-justify-center">
          <Title className=" sm:ec-text-5xl  lg:ec-text-5xl xl:ec-text-7xl ec-text-center lg:!ec-text-left ec-flex ec-flex-col xl:ec-gap-5 ec-gap-2  ">
            <div>
              An <span className=" ec-text-yellow-500">Ecosystem</span>
            </div>
            <div>
              built for <span className=" ec-text-yellow-500">Engineers</span>
            </div>
          </Title>
          <Text className=" ec-font-medium md:ec-text-xl ec-max-w-lg lg:ec-max-w-none  lg:ec-w-[85%] lg:!ec-text-left ec-text-center">
            Transforming the way in which individuals and organizations Engage,
            Collaborate and Grow in the world of engineering along their career
            trajectory.<div className=" ec-h-3 ec-w-min"></div> Building Better
            Place For Engineers!
            <span className=" ec-text-3xl">🛸</span>
          </Text>
          <Button
            className="ec-border-[#fff] ec-flex-wrap ec-h-10 ec-text-[#489ef9]  ec-rounded-full ec-font-medium md:ec-text-lg ec"
            variant="outline"
            rightIcon={<RocketLaunch />}
            component="a"
            target="_blank"
            href="https://nas.io/Engineers-Cradle"
            onClick={() => {
              scrollTo({ x: 0, y: 500 });
              ReactGA.event({
                category: "Join the Ecosystem Button Clicked",
                action: "Join the Ecosystem Button Clicked",
                label: "Join the Ecosystem Button Clicked",
                value: 1,
              });
              window?.analytics?.track(
                "Section 1 -- Join the Ecosystem Button Clicked"
              );
              // router.push(`https://beta.engineerscradle.com/register/`);
            }}
          >
            Join the Ecosystem
          </Button>
        </div>
        <div className=" ec-w-full ec-flex ec-items-center ec-justify-center ec-p-3">
          {/* <Image src="/assets/LandingPage/Hero1_2/Section1_2.svg" /> */}
        </div>
      </div>
    </div>
  );
}
