import { Text } from "@mantine/core";
import React from "react";

export default function S3Professional() {
  return (
    <>
      <Text className=" ec-font-bold ec-text-xl ec-tracking-wide">
        Struggling as an Engineer?{" "}
        <span className="ec-text-[#eab308]">NO WORRIES!</span>
      </Text>
      <Text className=" ec-text-lg">
        Be a part of like-minded community of engineers and organization across
        your area of interest. Express yourself, build your technical identity
        and contribute to the world of engineering by engaging with different
        activities and content.
      </Text>
    </>
  );
}
