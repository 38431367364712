import LandingPage from "@components/pages/landingPage";
import Head from "next/head";
import React from "react";
import ReactGA from "react-ga";

export default function LandingScreen() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window?.analytics?.track("Career Goals Form - Personal Details");
  }, []);

  return (
    <div className="ec-bg-black ec-w-screen ec-h-screen ec-overflow-x-hidden ec-overflow-y-scroll">
      <Head>
        <title>Engineers Cradle</title>
        {/* <meta name="description" content="Test Description" />
        <meta property="og:title" content="Engineers Cradle" />
        <meta property="og:description" content="Test Description" /> */}
        <meta property="og:image" content="https://engineerscradle.com/assets/Logo_sm.svg" />
      </Head>
      <LandingPage />
    </div>
  );
}

LandingScreen.getLayout = function getLayout(page) {
  return page;
};
