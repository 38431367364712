import Section1 from "./section1";
import Section2 from "./section2";
import Section3 from "./section3";
import Section4 from "./section4";
import Section5 from "./section5";
import Section6 from "./section6";
import Section7 from "./section7";
import MainFooter from "@components/common/footer/mainFooter";

export default function LandingPage() {
  return (
    <div className="ec-flex !ec-bg-black/70 ec-text-gray-100 ec-flex-col ec-w-full ec-gap-28">
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <MainFooter />
    </div>
  );
}
