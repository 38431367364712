import { Button, Container, Text, Title } from "@mantine/core";
import Image from "next/image";
import { useRouter } from "next/router";

export default function Section7() {
  const router = useRouter();
  return (
    <div className="  ec-w-full  -ec-mb-24  ec-items-center ec-justify-center ec-flex  lg:!ec-p-10 ec-flex-col ec-gap-4">
      <Container className=" ec-w-full" size="xl">
        <div
          className={`!ec-w-full ec-bg-[#151E31]   ec-flex ec-items-center ec-flex-col sm:!ec-flex-row ec-justify-center  ec-rounded-3xl ec-gap-2 ec-h-[400px]`}
        >
          <div className="ec-hidden ec-flex-1 ec-relative lg:ec-flex ec-h-full ec-w-full lg:!ec-w-[20%] ec-items-center ec-justify-center ec-p-3 ">
            <Image
              fill
              alt="Rocket"
              src="/assets/LandingPage/Hero7/EC_Hero7_1.svg"
              fit="cover"
              className=" ec-w-full ec-h-full ec-m-7 ec-ml-0"
            />
          </div>
          <div className=" ec-flex-1 ec-flex ec-flex-col ec-gap-5 ec-p-3 ec-items-center ec-justify-center">
            <Title className=" sm:!ec-text-3xl lg:!ec-text-4xl ec-text-xl ec-text-center  ec-text-white">
              “Building Better Place For Engineers”
            </Title>
            <Text className="  sm:!ec-text-lg lg:!ec-text-xl ec-text-md ec-font-medium ec-w-full ec-text-center">
              Discover your inner potential and capabilities being part of our
              ecosystem.
            </Text>
            <Button
              rightIcon={
                <div>🚀</div>
                // <RocketLaunch />
              }
              size="xl"
              component="a"
              target="_blank"
              href="https://nas.io/Engineers-Cradle"
              onClick={() => {
                window?.analytics?.track(
                  "Section 7 -- Join the Ecosystem Button Clicked"
                );
                // router.push("https://linktr.ee/engineers_cradle");
              }}
              variant="outline"
              className="ec-rounded-full ec-text-md ec-border-[#fff] ec-text-[#489ef9]"
            >
              Join the Ecosystem
            </Button>
          </div>
          <div className=" ec-hidden ec-flex-1 ec-relative lg:ec-flex ec-h-full ec-w-full lg:!ec-w-[20%] ec-items-center ec-justify-center ec-p-3 ">
            <Image
              fill
              alt="Rocket"
              src="/assets/LandingPage/Hero7/EC_Hero7_2.svg"
              fit="cover"
              className=" ec-w-full ec-h-full ec-m-7 ec-ml-0"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
