import Logo from "@components/common/logo";
import {
  Burger,
  Button,
  Container,
  Group,
  Header,
  MediaQuery,
  Menu,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Link from "next/link";

import { useRouter } from "next/router";
import { useState } from "react";

export default function MainHeader({ handleBurgerClick, opened, menu = true }) {
  const router = useRouter();
  const matches = useMediaQuery("(min-width: 900px)");
  const isHome = router.pathname === "/";

  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <Header
      height={60}
      className=" ec-w-full ec-p-3 ec-bg-transparent ec-border-none"
    >
      <Container size="full">
        <div className=" ec-flex ec-items-center ec-h-full xl:ec-px-10 ec-justify-between">
          <Group>
            {menu && (
              <MediaQuery largerThan="sm" className=" sm:ec-hidden">
                <Burger size="md" opened={opened} onClick={handleBurgerClick} />
              </MediaQuery>
            )}
            <Logo />
          </Group>
          <Group>
            {matches ? (
              <>
                {isHome && (
                  <Title
                    href="https://aspireec.in/open-innovation/"
                    component="a"
                    target="_blank"
                    className=" ec-cursor-pointer ec-font-medium"
                    order={5}
                  >
                    Innovation Challenge
                  </Title>
                )}
                {isHome && (
                  <Title
                    href="https://aspireec.in/learning/"
                    component="a"
                    target="_blank"
                    className=" ec-cursor-pointer ec-font-medium"
                    order={5}
                  >
                    Upskill
                  </Title>
                )}
                {isHome && (
                  <Title
                    href="https://aspireec.in/"
                    component="a"
                    target="_blank"
                    className=" ec-cursor-pointer ec-font-medium"
                    order={5}
                  >
                    ASPIRE Club
                  </Title>
                )}
                {isHome && (
                  <Title
                    href="https://linktr.ee/engineers_cradle"
                    component="a"
                    target="_blank"
                    className=" ec-cursor-pointer ec-font-medium"
                    order={5}
                  >
                    Explore EC
                  </Title>
                )}
                {isHome && (
                  <Title
                    href="https://forms.gle/d7SNcTaBMC2dayEZ7"
                    component="a"
                    target="_blank"
                    className=" ec-cursor-pointer ec-font-medium"
                    order={5}
                  >
                    We Are Hiring
                  </Title>
                )}
                <div className=" ec-flex ec-flex-row ec-gap-2 ec-items-center">
                  <Button
                    variant="outline"
                    className=" ec-rounded-full ec-text-md ec-border-[#fff] ec-text-[#489ef9]"
                    component="a"
                    target="_blank"
                    href="https://nas.io/Engineers-Cradle"
                  >
                    Join the Ecosystem
                  </Button>
                  {/* <Button onClick={() => router.push(`/auth/sign-up`)}>
                            Register
                        </Button> */}
                </div>
              </>
            ) : (
              <Menu
                opened={menuOpened}
                onChange={setMenuOpened}
                shadow="md"
                width={200}
              >
                <Menu.Target>
                  <Burger color="white" opened={menuOpened} title="Menu" />
                </Menu.Target>

                <Menu.Dropdown>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://aspireec.in/open-innovation/"
                    >
                      Innovation Challenge
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://aspireec.in/learning/"
                    >
                      Upskill
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://aspireec.in/"
                    >
                      ASPIRE Club
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://linktr.ee/engineers_cradle"
                    >
                      Explore EC
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://forms.gle/d7SNcTaBMC2dayEZ7"
                    >
                      We Are Hiring
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link
                      className=" ec-text-white ec-no-underline"
                      target="_blank"
                      href="https://nas.io/Engineers-Cradle"
                    >
                      Join the Ecosystem
                    </Link>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>
        </div>
      </Container>
    </Header>
  );
}
