import { Text } from "@mantine/core";
import React from "react";

export default function S3Industries() {
  return (
    <>
      <Text className=" ec-font-bold ec-text-xl ec-tracking-wide">
        Struggling with your technology and people?{" "}
        <span className="ec-text-[#eab308]">NO WORRIES!</span>
      </Text>
      <Text className=" ec-text-lg">
        Engage, Share and Discover right people, organizations and content to
        boost your organizations productivity and visibility across world of
        technology.
      </Text>
      <Text className=" ec-text-lg">
        Stay ahead in technology and resources being part of technical
        ecosystem!
      </Text>
    </>
  );
}
