import {
  Badge,
  Button,
  Container,
  Space,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import S3Industries from "./industries";
import S3Organization from "./organization";
import S3Professional from "./professional";
import S3Student from "./student";

const TabsList = [
  {
    id: "ORGANIZATIONS",
    Title: "ORGANIZATIONS",
    ImageUrl: "/assets/LandingPage/Section3_1/Image1.svg",
    description: <S3Organization />,
    buttons: [
      {
        Text: "Join the Ecosystem",
        Link: "https://nas.io/Engineers-Cradle",
      },
      {
        Text: "Know more",
        Link: "https://engineerscradle.notion.site/Organizations-c4130cfe971b484f95ac215c323ba8b4",
      },
    ],
  },
  {
    id: "INDUSTRIES",
    Title: "INDUSTRIES",
    ImageUrl: "/assets/LandingPage/Section3_1/Image2.svg",
    description: <S3Industries />,
    buttons: [
      {
        Text: "Join the Ecosystem",
        Link: "https://nas.io/Engineers-Cradle",
      },
      {
        Text: "Know more",
        Link: "https://engineerscradle.notion.site/Industries-cf69cb7ab05840b9ad10ae260628968c",
      },
    ],
  },
  {
    id: "PROFESSIONALS",
    Title: "PROFESSIONALS",
    ImageUrl: "/assets/LandingPage/Section3_1/Image3.svg",
    description: <S3Professional />,
    buttons: [
      {
        Text: "Join the Ecosystem",
        Link: "https://nas.io/Engineers-Cradle",
      },
      {
        Text: "Know more",
        Link: "https://engineerscradle.notion.site/Industry-Professionals-cd315928831b43168d770575099423dc",
      },
    ],
  },
  {
    id: "STUDENTS",
    Title: "STUDENTS",
    ImageUrl: "/assets/LandingPage/Section3_1/Image4.svg",
    description: <S3Student />,
    buttons: [
      {
        Text: "Join the Ecosystem",
        Link: "https://nas.io/Engineers-Cradle",
      },
      {
        Text: "Know more",
        Link: "https://engineerscradle.notion.site/Students-597bf8dc10cd438eb0e5c44ba0ff0ae5",
      },
    ],
  },
];

export default function Section3() {
  const router = useRouter();
  const { tab } = router.query;
  const matches = useMediaQuery("(min-width: 900px)");
  const [orientation, setOrientation] = useState("horizontal");
  const [activeTab, setActiveTab] = useState(TabsList[0].Title);

  useEffect(() => {
    if (matches) {
      setOrientation("horizontal");
    } else {
      setOrientation("vertical");
    }
  }, [matches]);

  return (
    <div className=" ec-w-full -ec-mt-20">
      <Container id="SELECT" size="xl">
        <div className=" ec-w-full ec-items-center ec-justify-center ec-flex ec-flex-col ec-gap-7">
          <div className=" ec-flex ec-flex-col ec-items-center ec-justify-center ec-gap-2 md:!ec-gap-5">
            <Title className=" sm:!ec-text-3xl lg:!ec-text-5xl ec-text-xl ec-font-bold ec-text-center lg:!ec-text-left ">
              Why{" "}
              <span
                span
                className="ec-text-transparent ec-bg-clip-text ec-bg-gradient-to-r ec-from-[#0065CF] ec-to-[#1FAE8C]"
              >
                Engineer&apos;s Cradle
              </span>
            </Title>
            <Text className=" ec-font-medium md:ec-text-2xl ec-text-center">
              Engineer&apos;s cradle is your one stop solution for all your
              exploratory needs.
            </Text>
          </div>
          <Tabs
            orientation={orientation}
            variant="unstyled"
            onTabChange={(value) => {
              window?.analytics?.track(`Section 3 -- Tabs Button Clicked`, {
                Tab: value,
              });
              router.push(`?tab=${value}`);
              setActiveTab(value);
            }}
            value={tab ?? activeTab}
            styles={(theme) => ({
              tab: {
                backgroundColor: "#0065CF30",
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.gray[5],
                fontWeight: "bold",
                border: `3px solid transparent`,
                borderRadius: "7px",
                padding: "10px",
                fontSize: theme.fontSizes.lg,
                margin: 10,
                "&[data-active]": {
                  backgroundColor: "#0065CF30",
                  borderColor: "#47F2C9",
                  color: theme.white,
                  fontWeight: "bolder",
                },
              },
            })}
            className=" ec-w-full ec-flex ec-flex-col ec-gap-5"
          >
            <Tabs.List className="ec-flex-nowrap">
              {TabsList.map((tab, index) => (
                <Tabs.Tab
                  key={tab?.Title}
                  className={"ec-w-full"}
                  value={tab?.Title}
                >
                  {tab?.Title}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            {TabsList.map((tab, index) => (
              <Tabs.Panel value={tab?.Title} key={tab?.description}>
                <Section3Card
                  Title={tab?.Title}
                  id={tab?.ImageUrl}
                  image={tab?.ImageUrl}
                  description={tab?.description}
                  buttons={tab?.buttons}
                  hashes={tab?.hashes}
                />
              </Tabs.Panel>
            ))}
          </Tabs>
        </div>
      </Container>
    </div>
  );
}

const Section3Card = (
  { id, Title, description, image, buttons, hashes },
  e
) => {
  return (
    <div
      key={id}
      className="ec-w-full ec-flex ec-flex-col lg:ec-flex-row ec-items-center ec-justify-center ec-gap-5 ec-p-5"
    >
      <div className=" ec-w-full ec-h-full ec-justify-evenly ec-flex ec-flex-col ec-gap-3">
        {description}
        <Space h="xl" />
        <div className=" ec-flex ec-flex-col ec-gap-3">
          <div className=" ec-flex ec-flex-row ec-gap-3">
            {buttons &&
              buttons?.length > 0 &&
              buttons.map((b, i) => (
                <Button
                  component="a"
                  target="_blank"
                  className=" ec-rounded-full ec-text-md ec-border-[#fff] ec-text-[#489ef9]"
                  onClick={() => {
                    window?.analytics?.track(
                      `Section 3 -- ${Title} Tab Clicked`,
                      {
                        Tab: Title,
                        Button: b?.Text,
                        link: b?.Link,
                      }
                    );
                  }}
                  variant="outline"
                  key={b?.Text}
                  href={b?.Link}
                >
                  {b.Text}
                </Button>
              ))}
          </div>
          {hashes &&
            hashes?.length > 0 &&
            hashes.map((h, i) => (
              <Badge className=" ec-w-min" key={h}>
                {h}
              </Badge>
            ))}
        </div>
      </div>
      <div className=" ec-w-full ec-relative md:ec-w-[90%] ec-flex ec-items-center ec-justify-center ec-overflow-hidden">
        <Image
          src={image}
          alt="image"
          width={500}
          height={500}
          className="!ec-w-full !ec-h-[300px] ec-aspect-square"
        />
      </div>
    </div>
  );
};
