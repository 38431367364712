import { Text } from "@mantine/core";
import React from "react";

export default function S3Student() {
  return (
    <>
      <Text className=" ec-font-bold ec-text-xl ec-tracking-wide">
        Do you know why have you taken engineering?{" "}
        <span className="ec-text-[#eab308]">NO WORRIES!</span>
      </Text>
      <Text className=" ec-text-lg">
        Join EC Ecosystem and get access to required resources and opportunities
        as per your career goals. Be part of technical Ecosystem and engage
        yourself with Industry content, individuals and activities to expand
        your practical knowledge and to reach at your desired goals in world of
        technology.
      </Text>
    </>
  );
}
